<template>
    <div id="view_warper" ref="pdfDom" :class="{ pdf_download: pdfDownload }" class="view_warper">
        <h1 class="t-a-c">火灾痕迹物品提取清单</h1>
        <div flex="main:justify">
            <div class="inquiry-wrap">
                <span>起火单位/地址：</span>
                <span class="content">{{ formData.address }}</span>
            </div>
            <div class="inquiry-wrap">
                <span style="min-width:80px">提取日期:</span>
                <span>
                    <FormatInquiryTime :date="formData.createTime" pattern="{y},{m},{d}" />
                </span>
            </div>
        </div>
        <table border="1" cellspacing="0" cellpadding="0" class="table_content">
            <tr>
                <th class="vertical td-min-height" style="height: 54px;">序号</th>
                <th colspan="2">名称</th>
                <th style="width: 40px">编号</th>
                <th colspan="2" style="width: 80px">提取部位</th>
                <th colspan="2">规格</th>
                <th style="width: 40px">数量</th>
                <th colspan="5">特征</th>
            </tr>
            <tr v-for="(item, index) in formData.extractListingList" :key="index">
                <td style="height: 68px;">
                    <span>{{ index + 1 }}</span>
                </td>
                <td colspan="2">
                    <span>{{ item.name }}</span>
                </td>
                <td>
                    <span>{{ item.number }}</span>
                </td>
                <td colspan="2">
                    <span>{{ item.part }}</span>
                </td>
                <td colspan="2">
                    <span>{{ item.specification }}</span>
                </td>
                <td>
                    <span>{{ item.acount }}</span>
                </td>
                <td colspan="5">
                    <span>{{ item.feature }}</span>
                </td>
            </tr>
            <template v-if="formData.extractListingList && formData.extractListingList.length < 5">
                <tr v-for="index in 5 - formData.extractListingList.length" :key="index + 'a'">
                    <td style="height: 68px;">
                        <span>{{ index + formData.extractListingList.length }}</span>
                    </td>
                    <td colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td>
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td>
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="5">
                        <span>&nbsp;</span>
                    </td>
                </tr>
            </template>
            <tr>
                <th v-if="formData.extractCollecterList" class="vertical" :rowspan="extractCollecterListLength">
                    提取人
                </th>
                <th colspan="2" class="td-min-height">姓名</th>
                <th colspan="7">工作单位</th>
                <th colspan="4">签名</th>
            </tr>
            <tr v-for="(item, index) in formData.extractCollecterList" :key="index + 'b'">
                <td colspan="2" style="height: 48px;">
                    <span>{{ item.name }}</span>
                </td>
                <td colspan="7">
                    <span>{{ item.org }}</span>
                </td>
                <td colspan="4" class="image-cell">
                    <img v-if="item.signature" :src="item.signature" alt="" srcset="" />
                </td>
            </tr>

            <template v-if="formData.extractCollecterList && formData.extractCollecterList.length < 2">
                <tr v-for="index in 2 - formData.extractCollecterList.length" :key="index + 'b'">
                    <td colspan="2" style="height: 48px;">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="7">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="4">
                        <span>&nbsp;</span>
                    </td>
                </tr>
            </template>

            <tr>
                <th v-if="formData.extractWitnessList" class="vertical letter-spacing"
                    :rowspan="Math.max(3, formData.extractWitnessList.length + 1)">证人或当事人
                </th>
                <th colspan="2" class="td-min-height">姓名</th>
                <th colspan="3">身份证件</th>
                <th colspan="2">单位或住址</th>
                <th colspan="2">联系电话</th>
                <th colspan="4">签名</th>
            </tr>
            <tr v-for="(item, index) in formData.extractWitnessList" :key="index + 'g'">
                <td colspan="2" style="height: 48px;">
                    <span>{{ item.name }}</span>
                </td>
                <td colspan="3">
                    <span>{{ item.cardNum }}</span>
                </td>
                <td colspan="2">
                    <span>{{ item.org }}</span>
                </td>
                <td colspan="2">
                    <span>{{ item.phone }}</span>
                </td>
                <td colspan="4" class="image-cell">
                    <img v-if="item.signature" :src="item.signature" alt="" srcset="" />
                    <!-- <span>{{ item.signature }}</span> -->
                </td>
            </tr>

            <template v-if="formData.extractWitnessList && formData.extractWitnessList.length < 2">
                <tr v-for="index in 2 - formData.extractWitnessList.length" :key="index + 'c'">
                    <td colspan="2" style="height: 48px;">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="3">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="4" class="image-cell">
                        &nbsp;
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import FormatInquiryTime from '@/components/FormatInquiryTime'
import { checkType } from '@/utils/index';
export default {
    components: { FormatInquiryTime },
    props: {
        formData: {
            type: Object
        },
        pdfDownload: {
            type: Boolean,
        }
    },
    computed: {
        extractCollecterListLength() {
            return Math.max(3, this.formData.extractCollecterList?.length + 1)
        },
        formatAddress() {
            return address => {
                if (!address || !address.length) return '';
                if (checkType(address) === 'string') {
                    return address.split(',')[2]
                } else if (checkType(address) === 'array') {
                    return address[2];
                }
            };
        },
    }
}
</script>
<style scoped lang="scss">
.view_warper {
    font-size: 17px;
    color: #000;
    padding: 15px 30px;
    overflow-y: auto;

    &.pdf_download {
        padding: 63.5px 79.25px;
        font-size: 22px;
    }

    .inquiry-wrap {
        display: flex;
        line-height: 2.2;

        .content {
            padding-left: 5px;
            flex: 1;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::after {
                content: '';
                width: 100%;
                display: block;
                border-bottom: 1px solid #000;
                position: absolute;
                bottom: 7px;
            }
        }
    }

    .table_content {
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        vertical-align: center;
        box-sizing: border-box;
        color: #606266;
        font-size: 13px;
        table-layout: fixed;
        height: 750px;
        font-size: 16px;

        .td-min-height {
            height: 36px;
        }

        .vertical {
            width: 36px;
            writing-mode: vertical-rl;
        }

        .letter-spacing {
            text-align-last: justify;
            padding: 4px 0;
        }

        .image-cell {
            width: 100%;
            height: auto;
            text-align: center;
        }

        .image-cell img {
            max-width: 32%;
            margin: 0 auto;
            height: auto;
            display: block;
        }

        td {
            position: relative;
            // 让字母数字 自动换行
            word-wrap: break-word;
            word-break: break-all;

            &>input,
            &>textarea,
            &>.box {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                text-align: left;
                padding-left: 10px;
                box-sizing: border-box;
                border: none;
                resize: none;
                font-family: inherit;
                background-color: #ebf0ff;
            }

            .el-input__inner {
                border: none;
            }

            input[type="date"],
            input[type="time"] {
                background-color: #ebf0ff;
            }


        }
    }
}
</style>