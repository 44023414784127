<template>
    <span class="container">
        <span v-if="formatTime.y" class="line year">{{ formatTime.y || '/' }}</span><span v-if="formatTime.y"
            class="text">年</span>
        <span v-if="formatTime.m" class="line">{{ formatTime.m || '/' }}</span><span v-if="formatTime.m">月</span>
        <span v-if="formatTime.d" class="line">{{ formatTime.d || '/' }}</span><span v-if="formatTime.d">日</span>
        <span v-if="formatTime.h" class="line">{{ formatTime.h || '/' }}</span><span v-if="formatTime.h">时</span>
        <span v-if="formatTime.i" class="line">{{ formatTime.i || '/' }}</span><span v-if="formatTime.i">分</span>
    </span>
</template>

<script>
export default {
    props: {
        date: {
            type: [Date, String, Number],
            default: () => new Date()
        },
        pattern: {
            type: String,
            default: '{y},{m},{d},{h},{i},{s}'
        }
    },
    data() {
        return {
            newDate: null
        }
    },
    watch: {
        date: {
            handler(val) {
                this.newDate = new Date(val)
            },
            immediate: true
        }
    },
    computed: {
        formatTime() {
            let newDate = new Date(this.date)
            const formatObj = {
                y: newDate.getFullYear(),
                m: newDate.getMonth() + 1,
                d: newDate.getDate(),
                h: newDate.getHours(),
                i: newDate.getMinutes(),
                s: newDate.getSeconds(),
                a: newDate.getDay()
            }
            const dateObj = {}
            this.pattern.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
                let value = formatObj[key]
                if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
                if (result.length > 0 && value < 10) {
                    value = '0' + value
                }
                dateObj[key] = value
                return value || 0
            })
            return dateObj
        }
    }
}
</script>
<style scoped lang="scss">
.container {
    display: flex;

    .line {
        flex: 1;
        text-align: center;
        position: relative;
        min-width: 8px;

        &::after {

            content: '';
            width: 100%;
            display: block;
            border-bottom: 1px solid #000;
            position: absolute;
            bottom: 7px;
        }
    }

    .text {
        text-decoration: none;
    }

    .year {
        flex: 2;
    }
}
</style>