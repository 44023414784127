<template>
  <div class="inventory_wrap template-page">
    <div v-loading.fullscreen.lock="loading" class="content" flex="main:justify">
      <div v-if="!pdfDownload" class="flex_1 m-r-10 b-r p-l-10 p-t-10 p-r-10 p-b-20 left-page">
        <avue-crud ref="listing" class="m-b-20" :data="formData.extractListingList" v-model="listingForm"
          :option="listingOption" @row-update="(form, index, done) => rowUpdate(form, index, done, 'listing')"
          @row-del="(form, index) => rowDel(form, index, 'listing')"
          @row-save="(form, done) => rowSave(form, done, 'listing')"
          @row-click="(row, event, column) => handleRowClick(row, event, column, 'listing')"
          :upload-before="uploadListingBefore" :upload-preview="uploadListingPreview">
          <template slot="uploadFile" slot-scope="{row}">
            <div v-if="row.uploadFile"
              style="width: 100%;display: flex;justify-content: space-around;align-items: center;">
              <div v-for="(item, index) in row.uploadFile.split(',')" :key="index">
                <i :class="isPdf(item) ? 'el-icon-document' : 'el-icon-picture'" style="font-size: 20px;"></i>
              </div>
            </div>
          </template>
        </avue-crud>
        <avue-crud ref="collecter" class="m-b-20" :data="formData.extractCollecterList" v-model="collecterForm"
          :option="collecterOption" @row-del="(form, index) => rowDel(form, index, 'collecter')"
          @row-save="(form, done) => rowSave(form, done, 'collecter')"
          @row-update="(form, index, done) => rowUpdate(form, index, done, 'collecter')"
          @row-click="(row, event, column) => handleRowClick(row, event, column, 'collecter')">
          <template slot="signature" slot-scope="{row}">
            <img v-if="row.signature" :src="row.signature" alt="" height="32" />
          </template>
          <template slot="signatureForm" slot-scope="scope">
            <div flex="cross:center main:justify">
              <img v-if="scope.value && scope.value.length" :src="scope.value" alt="" height="32" />
              <el-link class="margin_left_10" type="primary" @click="(signatureVisible = true), (refKey = 'collecter')">
                签名</el-link>
            </div>
          </template>
          <template slot="cardImgForm" slot-scope="{}">
            <el-upload :action="cardUploadUrl" :headers="headers" :show-file-list="false" :on-success="handleOnSuccess"
              :before-upload="() => {
                (loading = true), (refKey = 'collecter');
              }
                ">
              <el-button size="mini" type="success" plain>上传身份证图片</el-button>
            </el-upload>
          </template>
        </avue-crud>
        <avue-crud ref="witness" class="m-b-20" :data="formData.extractWitnessList" v-model="witnessForm"
          :option="witnessOption" @row-del="(form, index) => rowDel(form, index, 'witness')"
          @row-save="(form, done) => rowSave(form, done, 'witness')"
          @row-update="(form, index, done) => rowUpdate(form, index, done, 'witness')"
          @row-click="(row, event, column) => handleRowClick(row, event, column, 'witness')">
          <template slot="signature" slot-scope="{row}">
            <img v-if="row.signature" :src="row.signature" alt="" height="32" />
          </template>
          <template slot="signatureForm" slot-scope="scope">
            <div flex="cross:center main:justify">
              <img v-if="scope.value && scope.value.length" :src="scope.value" alt="" height="32" />
              <el-link class="margin_left_10" type="primary" @click="(signatureVisible = true), (refKey = 'witness')">签名
              </el-link>
            </div>
          </template>


          <template slot="cardImgForm" slot-scope="{}">
            <el-upload :action="cardUploadUrl" :headers="headers" :show-file-list="false" :before-upload="() => {
              (loading = true), (refKey = `witness`);
            }
              " :on-success="handleOnSuccess">
              <el-button size="mini" type="success" plain>上传身份证图片</el-button>
            </el-upload>
          </template>
        </avue-crud>
      </div>
      <InventoryTem class="flex_1 template" :formData="formData" :pdfDownload="pdfDownload" />
    </div>
    <SignatureDialog v-model="signatureVisible" @submit="generate" />
    <DownloadBtn class="download-btn" :pdfDownload="pdfDownload" title="火灾痕迹物品提取清单" :keyId="params.keyId"
      :formData="formData" :moduleName="currentModule.moduleName" @saveData="submitFormBackBefore" />
    <FooterBtn class="footer" :pdfDownload="pdfDownload" :submitDis="false" @submit="submitForm" @cancel="handleCancel" />
    <PdfDialog v-model="pdfVisible" :pdf-url="pdfUrl" />
  </div>
</template>
<script>
import DataFlow from '@/mixins/DataFlow'
import PdfDialog from '@/components/PdfDialog/PdfDialog.vue';
import FooterBtn from '@/components/FooterBtn'
import SignatureDialog from '@/components/SignatureDialog'
import DownloadBtn from '@/components/DownloadBtn'
import InventoryTem from '@/components/Template/inventory.vue'
import { getDoPersonList } from '@/api/archives/index.js'
export default {
  mixins: [DataFlow],
  components: {
    PdfDialog, FooterBtn, SignatureDialog, DownloadBtn, InventoryTem
  },
  data() {
    return {
      signatureVisible: false,
      isDownload: false,
      refKey: "",
      pdfVisible: false,
      pdfUrl: '',
      listingForm: {},
      collecterForm: {},
      witnessForm: {},
      collecterNameDic: []
    };
  },
  computed: {
    listingOption() {
      return {
        menuWidth: 100,
        editBtn: false,
        dialogType: "drawer",
        dialogWidth: "500",
        columnBtn: false,
        refreshBtn: false,
        addBtnText: "新增提取物",
        border: true,
        column: [
          {
            label: "名称",
            prop: "name",
            span: 24
          },
          {
            label: "编号",
            prop: "number",
            type: "number",
            span: 24,
            controlsPosition: "top",
            value: ((this.formData.listing && this.formData.listing.length) || 0) + 1,
            min: 0,
            step: 1,
            stepStrictly: true,
            precision: 0
          },
          {
            label: "提取部位",
            prop: "part",
            span: 24
          },
          {
            label: "规格",
            prop: "specification",
            type: "select",
            dicUrl: "/system/dict/data/type/quantifier_type?isDic=true",
            dicHeaders: this.headers,
            props: {
              res: "data",
              label: "dictLabel",
              value: "dictLabel"
            },
            span: 24
          },
          {
            label: "数量",
            prop: "acount",
            type: "number",
            span: 24,
            controlsPosition: "top",
            min: 0,
            step: 1,
            stepStrictly: true,
            precision: 0
          },
          {
            label: "特征",
            prop: "feature",
            span: 24
          },
          {
            label: "上传文件",
            prop: "uploadFile",
            type: "upload",
            listType: "picture-card",
            accept: 'image/png, image/jpeg, application/pdf',
            loadText: "附件上传中，请稍等",
            span: 24,
            action: "/common/upload?isDic=true",
            headers: this.headers,
            dataType: "string",
            propsHttp: {
              res: "data"
            }
          }
        ]
      };
    },
    witnessOption() {
      return {
        menuWidth: 100,
        editBtn: false,
        dialogType: "drawer",
        dialogWidth: "500",
        columnBtn: false,
        refreshBtn: false,
        addBtnText: "新增证人和当事人",

        border: true,
        column: [
          {
            label: "身份识别",
            prop: "cardImg",
            span: 24,
            hide: true
          },
          {
            label: "姓名",
            prop: "name",
            span: 24
          },
          {
            label: "身份证号码",
            prop: "cardNum",
            span: 24
          },
          {
            label: "单位或住址",
            prop: "org",
            span: 24
          },
          {
            label: "联系电话",
            prop: "phone",
            span: 24
          },
          {
            label: "电子签名",
            prop: "signature",
            dataType: 'string',
            span: 24,
            value: ''
          }
        ]
      };
    },
    collecterOption() {
      return {
        menuWidth: 100,
        editBtn: false,
        dialogType: "drawer",
        dialogWidth: "500",
        columnBtn: false,
        refreshBtn: false,
        addBtnText: "新增提取人",
        border: true,
        column: [
          {
            label: "身份识别",
            prop: "cardImg",
            span: 24,
            hide: true
          },
          {
            span: 24,
            label: "姓名",
            prop: "name",
            type: 'select',
            filterable: true,
            allowCreate: true,
            dicUrl: `/archives/archives/getDoPersonList/${this.params.archivesId}?isDic=true`,
            props: {
              res: 'rows',
              label: 'nickName',
              value: 'nickName',
            },
            dicFormatter: data => {
              data.data.forEach(ele => {
                ele.desc = ele.dept.deptName
              });
              return data.data
            }
          },
          {
            label: "工作单位",
            prop: "org",
            span: 24
          },
          {
            label: "电子签名",
            prop: "signature",
            dataType: 'string',
            span: 24,
            value: ''
          }
        ]
      };
    },
    cardUploadUrl() {
      return this.baseUrl + "/archives/notice/discern";
    },
  },
  methods: {
    uploadListingBefore(file, done, loading, column) {
      if (file.size >= 1024 * 1024 * 50) {
        this.$message.error('上传的文件大小超出限制的文件大小！允许的文件最大大小是：50MB！')
        loading()
      }
      const fileTypeFlag = ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type);
      if (!fileTypeFlag) {
        this.$message.error('上传附件只能是 JPG | PNG | PDF 格式!');
        loading()
      } else {
        done()
      }
    },
    uploadListingPreview(file, column, done) {
      if (this.isPdf(file.url)) {
        this.pdfVisible = true
        this.pdfUrl = file.url
      } else {
        done()
      }
    },
    handleOnSuccess(res) {
      if (!res.data.words_result_num) {
        this.$message.warning("无法识别，请上传清晰的证件照片");
      } else {
        this.$message.success("识别成功");
        const {
          姓名: { words: name },
          公民身份号码: { words: cardNum },
          住址: { words: org }
        } = res.data.words_result;
        Object.assign(this.$refs[this.refKey].tableForm, {
          name,
          cardNum,
          org
        });
      }
      this.loading = false;
    },
    generate(base64) {
      this.$refs[this.refKey].tableForm.signature = base64
    },
    beforeOpen(done, type, key) {
      done()
    },
    rowSave(form, done, key) {
      const formData = JSON.parse(JSON.stringify(form));
      var capitalizedWord = key.charAt(0).toUpperCase() + key.slice(1);
      this.formData[`extract${capitalizedWord}List`].push(formData);
      this.$message.success("操作成功");
      done();
    },
    rowUpdate(form, index, done, key) {
      const formData = JSON.parse(JSON.stringify(form));
      var capitalizedWord = key.charAt(0).toUpperCase() + key.slice(1);
      this.formData[`extract${capitalizedWord}List`].splice(index, 1, formData);
      this.$message.success("操作成功");
      done();
    },
    rowDel(form, index, key) {
      var capitalizedWord = key.charAt(0).toUpperCase() + key.slice(1);
      this.formData[`extract${capitalizedWord}List`].splice(index, 1);
      this.$message.success("操作成功");
      // done()
    },
    handleRowClick(row, event, column, key) {
      this.$refs[key].rowEdit(row, row.$index);
    },
    async beforeHandleData(params) {
      let res = await getDoPersonList(params.archivesId)
      console.log(res);
    },
    initForm() {
      console.log(this.archivesData);
      const { doPersonName, doPerson, doOtherPersonName, doOtherPerson } = this.archivesData
      const userNames = `${doPersonName}${doOtherPersonName ? ' ' + doOtherPersonName.trim() : ''}`
      const userIds = `${doPerson}${doOtherPerson ? ',' + doOtherPerson.trim() : ''}`
      const userNameList = userNames.split(' ')
      const userIdList = userIds.split(',')
      let userList = userNameList.map((name, i) => {
        return {
          label: name,
          value: userIdList[i]
        }
      })
      this.$nextTick(() => {
        this.collecterNameDic = userList
      })
      const archives = this.currentRow?.archives || {};
      let initForm = {
        remark: "",
        time: 2,
        address: archives.address || '',
        extractCollecterList: [],
        extractListingList: [],
        extractWitnessList: []
      };
      return initForm
    },
  }
};
</script>
<style lang="scss" scoped></style>
