var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inventory_wrap template-page"},[_c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"content",attrs:{"flex":"main:justify"}},[(!_vm.pdfDownload)?_c('div',{staticClass:"flex_1 m-r-10 b-r p-l-10 p-t-10 p-r-10 p-b-20 left-page"},[_c('avue-crud',{ref:"listing",staticClass:"m-b-20",attrs:{"data":_vm.formData.extractListingList,"option":_vm.listingOption,"upload-before":_vm.uploadListingBefore,"upload-preview":_vm.uploadListingPreview},on:{"row-update":function (form, index, done) { return _vm.rowUpdate(form, index, done, 'listing'); },"row-del":function (form, index) { return _vm.rowDel(form, index, 'listing'); },"row-save":function (form, done) { return _vm.rowSave(form, done, 'listing'); },"row-click":function (row, event, column) { return _vm.handleRowClick(row, event, column, 'listing'); }},scopedSlots:_vm._u([{key:"uploadFile",fn:function(ref){
var row = ref.row;
return [(row.uploadFile)?_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-around","align-items":"center"}},_vm._l((row.uploadFile.split(',')),function(item,index){return _c('div',{key:index},[_c('i',{class:_vm.isPdf(item) ? 'el-icon-document' : 'el-icon-picture',staticStyle:{"font-size":"20px"}})])}),0):_vm._e()]}}],null,false,885025769),model:{value:(_vm.listingForm),callback:function ($$v) {_vm.listingForm=$$v},expression:"listingForm"}}),_c('avue-crud',{ref:"collecter",staticClass:"m-b-20",attrs:{"data":_vm.formData.extractCollecterList,"option":_vm.collecterOption},on:{"row-del":function (form, index) { return _vm.rowDel(form, index, 'collecter'); },"row-save":function (form, done) { return _vm.rowSave(form, done, 'collecter'); },"row-update":function (form, index, done) { return _vm.rowUpdate(form, index, done, 'collecter'); },"row-click":function (row, event, column) { return _vm.handleRowClick(row, event, column, 'collecter'); }},scopedSlots:_vm._u([{key:"signature",fn:function(ref){
var row = ref.row;
return [(row.signature)?_c('img',{attrs:{"src":row.signature,"alt":"","height":"32"}}):_vm._e()]}},{key:"signatureForm",fn:function(scope){return [_c('div',{attrs:{"flex":"cross:center main:justify"}},[(scope.value && scope.value.length)?_c('img',{attrs:{"src":scope.value,"alt":"","height":"32"}}):_vm._e(),_c('el-link',{staticClass:"margin_left_10",attrs:{"type":"primary"},on:{"click":function($event){(_vm.signatureVisible = true), (_vm.refKey = 'collecter')}}},[_vm._v(" 签名")])],1)]}},{key:"cardImgForm",fn:function(ref){return [_c('el-upload',{attrs:{"action":_vm.cardUploadUrl,"headers":_vm.headers,"show-file-list":false,"on-success":_vm.handleOnSuccess,"before-upload":function () {
              (_vm.loading = true), (_vm.refKey = 'collecter');
            }}},[_c('el-button',{attrs:{"size":"mini","type":"success","plain":""}},[_vm._v("上传身份证图片")])],1)]}}],null,false,1311678273),model:{value:(_vm.collecterForm),callback:function ($$v) {_vm.collecterForm=$$v},expression:"collecterForm"}}),_c('avue-crud',{ref:"witness",staticClass:"m-b-20",attrs:{"data":_vm.formData.extractWitnessList,"option":_vm.witnessOption},on:{"row-del":function (form, index) { return _vm.rowDel(form, index, 'witness'); },"row-save":function (form, done) { return _vm.rowSave(form, done, 'witness'); },"row-update":function (form, index, done) { return _vm.rowUpdate(form, index, done, 'witness'); },"row-click":function (row, event, column) { return _vm.handleRowClick(row, event, column, 'witness'); }},scopedSlots:_vm._u([{key:"signature",fn:function(ref){
            var row = ref.row;
return [(row.signature)?_c('img',{attrs:{"src":row.signature,"alt":"","height":"32"}}):_vm._e()]}},{key:"signatureForm",fn:function(scope){return [_c('div',{attrs:{"flex":"cross:center main:justify"}},[(scope.value && scope.value.length)?_c('img',{attrs:{"src":scope.value,"alt":"","height":"32"}}):_vm._e(),_c('el-link',{staticClass:"margin_left_10",attrs:{"type":"primary"},on:{"click":function($event){(_vm.signatureVisible = true), (_vm.refKey = 'witness')}}},[_vm._v("签名 ")])],1)]}},{key:"cardImgForm",fn:function(ref){return [_c('el-upload',{attrs:{"action":_vm.cardUploadUrl,"headers":_vm.headers,"show-file-list":false,"before-upload":function () {
            (_vm.loading = true), (_vm.refKey = "witness");
          },"on-success":_vm.handleOnSuccess}},[_c('el-button',{attrs:{"size":"mini","type":"success","plain":""}},[_vm._v("上传身份证图片")])],1)]}}],null,false,341604353),model:{value:(_vm.witnessForm),callback:function ($$v) {_vm.witnessForm=$$v},expression:"witnessForm"}})],1):_vm._e(),_c('InventoryTem',{staticClass:"flex_1 template",attrs:{"formData":_vm.formData,"pdfDownload":_vm.pdfDownload}})],1),_c('SignatureDialog',{on:{"submit":_vm.generate},model:{value:(_vm.signatureVisible),callback:function ($$v) {_vm.signatureVisible=$$v},expression:"signatureVisible"}}),_c('DownloadBtn',{staticClass:"download-btn",attrs:{"pdfDownload":_vm.pdfDownload,"title":"火灾痕迹物品提取清单","keyId":_vm.params.keyId,"formData":_vm.formData,"moduleName":_vm.currentModule.moduleName},on:{"saveData":_vm.submitFormBackBefore}}),_c('FooterBtn',{staticClass:"footer",attrs:{"pdfDownload":_vm.pdfDownload,"submitDis":false},on:{"submit":_vm.submitForm,"cancel":_vm.handleCancel}}),_c('PdfDialog',{attrs:{"pdf-url":_vm.pdfUrl},model:{value:(_vm.pdfVisible),callback:function ($$v) {_vm.pdfVisible=$$v},expression:"pdfVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }